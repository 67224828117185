import { arrayOf, number, func, bool, object } from 'prop-types';
import classNames from 'classnames';
import { defineMessages } from '@alltrails/shared/react-intl';
import SectionMessage from '@alltrails/shared/denali/components/SectionMessage';
import useFormatMessage from '@alltrails/shared/hooks/useFormatMessage';
import Button from '@alltrails/shared/denali/components/Button';
import LanguageRegionCode from '@alltrails/shared/types/LanguageRegionCode';
import useLanguageRegionCode from '@alltrails/shared/hooks/useLanguageRegionCode';
import logCustomMapRouteCTAClicked from '@alltrails/analytics/events/logCustomMapRouteCTAClicked';
import CustomeMapMapSource from '@alltrails/analytics/enums/CustomMapMapSource';
import useDisplayMetric from 'hooks/useDisplayMetric';
import Info from '@alltrails/shared/icons/Info';
import { routePropTypes } from '../../../../proptypes/shared';
import { ADD_NEW_ROUTE, defaultRouteColor, UPDATE_FOR_INCOMING_ROUTES } from '../../../../hooks/useRoutes';
import { MapStatsUtil } from '../../../../utils/map_stats_util';
import { ServerCommunicationUtil } from '../../../../utils/server_communication_util';
import StaticMapImage from '../../StaticMapImage';
import { DEFAULT_ROUTE_COLOR } from '../RouteColorRadioButtons';
import Routes from '../Routes';
import RouteDetails from '../RouteDetails';
import * as styles from './styles/styles.module.scss';

const ROUTE_SECTIONS_STRINGS = defineMessages({
  SEARCH_FOR_LOCATION: { defaultMessage: 'Search for a location or click anywhere on the map to draw' },
  YOUR_ROUTE: { defaultMessage: 'Your route' },
  DRAW_ON_MAP: { defaultMessage: 'Draw on map' },
  UPLOAD_FROM_FILE: { defaultMessage: 'Upload from file' },
  CUSTOM_ROUTES_NEW_EXPERIENCE: { defaultMessage: 'Stay tuned for a new custom routing experience coming soon!' }
});

const initialColor = DEFAULT_ROUTE_COLOR;

const RouteSection = ({
  routes,
  summaryStats,
  dispatchRoutes,
  messagingChannel,
  canEdit,
  sortMode,
  mapId,
  updateMap,
  openTrailFormModal,
  isEditing,
  handleRouteSave,
  isSubmitting,
  updateExploreMap,
  hidePanel,
  dispatchWaypoints,
  ...props
}) => {
  const {
    formattedDefaultMessages: { SEARCH_FOR_LOCATION, YOUR_ROUTE, DRAW_ON_MAP, UPLOAD_FROM_FILE, CUSTOM_ROUTES_NEW_EXPERIENCE }
  } = useFormatMessage(ROUTE_SECTIONS_STRINGS);
  const languageRegionCode = useLanguageRegionCode();
  const displayMetric = useDisplayMetric();

  let addNewRoute;
  let handleRouteUpload;
  let openUploadModal;
  let actionButtons;

  if (canEdit) {
    addNewRoute = () => {
      const editIndex = routes.length;
      const route = {
        id: null,
        lineGeoStats: {
          distanceTotal: 0,
          elevationGain: 0
        },
        location: {
          longitude: null,
          latitude: null
        },
        lineDisplayProperty: {
          color: initialColor
        },
        sequence_num: editIndex + 1
      };
      const payload = { route, editIndex, mapSource: CustomeMapMapSource.Drawn };
      const action = { type: ADD_NEW_ROUTE, payload };

      logCustomMapRouteCTAClicked({ custom_map_cta: CustomeMapMapSource.Drawn });
      dispatchRoutes(action);
    };

    handleRouteUpload = payload => {
      if (mapId) {
        Object.assign(payload, { lineDisplayProperty: { color: defaultRouteColor } });
        dispatchRoutes({ type: ADD_NEW_ROUTE, payload: { route: payload, editIndex: null, mapSource: CustomeMapMapSource.Uploaded } });
      } else {
        updateExploreMap(payload);
        dispatchRoutes({ type: UPDATE_FOR_INCOMING_ROUTES, payload: payload.routes });
      }

      if (payload.waypoints) {
        dispatchWaypoints({ type: 'UPDATE_FOR_INCOMING_WAYPOINTS', payload: payload.waypoints });
      }
    };

    openUploadModal = () => {
      const url = mapId ? `/api/alltrails/maps/${mapId}/routes/gpx` : '/api/alltrails/maps/gpx';
      const createAsMap = mapId === null;
      const formModalProps = { routeMode: true, url, exploreFlag: false, handleRouteUpload, createAsMap };

      logCustomMapRouteCTAClicked({ custom_map_cta: CustomeMapMapSource.Uploaded });
      openTrailFormModal('tracks', formModalProps);
    };

    actionButtons =
      props.editIndex === null && !sortMode ? (
        <div className={styles.actionButtons}>
          <Button
            text={DRAW_ON_MAP}
            className={classNames(
              [LanguageRegionCode.French, LanguageRegionCode.German, LanguageRegionCode.English].includes(languageRegionCode) && styles.smaller
            )}
            onClick={addNewRoute}
            testId="map-creator-draw-on-map"
            width="full"
          />
          <Button
            text={UPLOAD_FROM_FILE}
            className={classNames(
              [LanguageRegionCode.French, LanguageRegionCode.German, LanguageRegionCode.English].includes(languageRegionCode) && styles.smaller
            )}
            onClick={openUploadModal}
            testId="map-creator-upload-from-file"
            width="full"
          />
        </div>
      ) : null;
  }

  const renderTotalRouteDetails = () => {
    if (!summaryStats || routes.length < 2 || props.editIndex !== null) {
      return null;
    }
    const { distance, elevation } = MapStatsUtil.distanceAndElevation(summaryStats, displayMetric, languageRegionCode);

    return (
      <div className={styles.routeDetailsContainer}>
        <RouteDetails distance={distance} elevation={elevation} />
      </div>
    );
  };

  const totalRouteDetails = renderTotalRouteDetails();
  return (
    <div>
      <div className={styles.routeHeaderContainer}>
        {isEditing && (
          <div className={styles.section}>
            <SectionMessage icon={{ Component: Info }} title={CUSTOM_ROUTES_NEW_EXPERIENCE} variant="caution" />
          </div>
        )}
        {isEditing && <h2 className={styles.routeContainerTitle}>{YOUR_ROUTE}</h2>}
        {props.editIndex !== null && <p className={styles.routeContainerSubtext}>{SEARCH_FOR_LOCATION}</p>}
      </div>
      {isEditing && routes.length > 0 && <div className={classNames(styles.divider, styles.marginSides, styles.marginTop)} />}
      {totalRouteDetails}
      {mapId && (
        <div className={styles.staticMapImage}>
          <StaticMapImage url={`/api/alltrails/v3/maps/${mapId}/static_map?size=480x200&key=${ServerCommunicationUtil.apiKey}`} onClick={hidePanel} />
        </div>
      )}
      <Routes
        routes={routes}
        editIndex={props.editIndex}
        sortMode={sortMode}
        dispatchRoutes={dispatchRoutes}
        editParams={props.editParams}
        messagingChannel={messagingChannel}
        handleFindLocationSelect={props.handleFindLocationSelect}
        mapboxAccessToken={props.mapboxAccessToken}
        canEdit={canEdit}
        mapId={mapId}
        updateMap={updateMap}
        handleRouteSave={handleRouteSave}
        isSubmitting={isSubmitting}
      />
      {routes.length > 0 && props.editIndex === null && <div className={styles.spacer} />}
      {actionButtons}
      <div className={classNames(styles.divider, styles.marginSides)} />
    </div>
  );
};

RouteSection.propTypes = {
  routes: arrayOf(routePropTypes),
  dispatchRoutes: func,
  canEdit: bool,
  sortMode: bool,
  mapId: number,
  updateMap: func,
  summaryStats: object,
  openTrailFormModal: func
};

RouteSection.defaultProps = {
  routes: [],
  dispatchRoutes: () => {},
  summaryStats: null,
  canEdit: false,
  sortMode: false,
  mapId: null,
  updateMap: () => {},
  openTrailFormModal: () => {}
};

export default RouteSection;
